import cx from 'classnames';
import { DashboardTableDataType } from 'entities/dashboard/types';
import { ComponentProps } from 'react';

import { TBody } from './components/TBody';
import { THead } from './components/THead';

import css from './index.module.css';

type Props = {
  title?: string;
  data: DashboardTableDataType[];
  valuePostfix?: string;
  valuePrefix?: string;
} & ComponentProps<'table'>;

export const Table = ({ title, className, data, valuePostfix, valuePrefix, ...props }: Props) => (
  <table className={cx(css.wrapper, className)} {...props}>
    {title ? <THead title={title} /> : null}
    <TBody valuePostfix={valuePostfix} valuePrefix={valuePrefix} data={data} />
  </table>
);
