import { ReactNode, createContext, useContext, useMemo } from 'react';

import { useDashboard } from '../hooks/useDashboard';
import { DashboardDataType, DashboardDetailsType, DashboardTableDataType } from '../types';

type DashboardContextType = {
  details: DashboardDetailsType | null;
  apr: DashboardTableDataType | null;
  maxDrawdown: DashboardTableDataType | null;
  sharpeRatio: DashboardTableDataType | null;
  sortinoRatio: DashboardTableDataType | null;
  calmarRatio: DashboardTableDataType | null;
  pnl: DashboardDataType[];
  isLoading: boolean;
  isAvailable: boolean;
  updateGraphData: (data: DashboardDataType[]) => void;
};

const DashboardContext = createContext<DashboardContextType>({} as DashboardContextType);

type Props = {
  children: ReactNode;
  wallet: string;
  selectedChain: string;
};

export const DashboardContextProvider = ({ children, wallet, selectedChain }: Props) => {
  const {
    details,
    apr,
    maxDrawdown,
    sharpeRatio,
    sortinoRatio,
    calmarRatio,
    pnl,
    isLoading,
    isAvailable,
    updateGraphData,
  } = useDashboard({
    wallet,
    selectedChain,
  });

  const values = useMemo(
    () => ({
      details,
      apr,
      maxDrawdown,
      sharpeRatio,
      sortinoRatio,
      calmarRatio,
      pnl,
      isLoading,
      isAvailable,
      updateGraphData,
    }),
    [
      details,
      apr,
      maxDrawdown,
      sharpeRatio,
      sortinoRatio,
      calmarRatio,
      isLoading,
      isAvailable,
      pnl,
      updateGraphData,
    ]
  );

  return <DashboardContext.Provider value={values}>{children}</DashboardContext.Provider>;
};

export const useDashboardContext = () => {
  const context = useContext(DashboardContext);

  if (!context) {
    throw new Error(`Dashboard context not found`);
  }

  return context;
};
