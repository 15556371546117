import cx from 'classnames';
import { ComponentProps } from 'react';

import css from './index.module.css';

type Props = {
  title: string;
  value: number | null;
} & ComponentProps<'div'>;

export const StrategiesCard = ({ title, value, className, ...props }: Props) => {
  if (!value) {
    return null;
  }

  return (
    <div className={cx(css.wrapper, className)} {...props}>
      <span className={cx('title3', css.value)}>{value}</span>
      <p className={cx('body', css.title)}>{title}</p>
    </div>
  );
};
