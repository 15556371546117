import { TimeFrame } from 'entities/types';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { fetchDashboardData } from 'shared/api/dashboard';
import { POLLING_INTERVAL, QUERY_ENABLED } from 'shared/lib/constants/global';

import {
  getApr,
  getCalmarRatio,
  getDetails,
  getMaxDrawdown,
  getPnl,
  getSharpeRatio,
  getSortinoRatio,
} from '../helpers';
import {
  DashboardDataType,
  DashboardDetailsType,
  DashboardTableDataType,
  MetricType,
} from '../types';

type Props = {
  wallet: string;
  selectedChain: string;
};

type HookReturnType = {
  details: DashboardDetailsType | null;
  apr: DashboardTableDataType | null;
  maxDrawdown: DashboardTableDataType | null;
  sharpeRatio: DashboardTableDataType | null;
  sortinoRatio: DashboardTableDataType | null;
  calmarRatio: DashboardTableDataType | null;
  pnl: DashboardDataType[];
  isLoading: boolean;
  isAvailable: boolean;
  updateGraphData: (data: DashboardDataType[]) => void;
};

const emptyDetails: DashboardDetailsType = {
  strategies: NaN,
  balance: NaN,
  usdBalance: NaN,
};

const emptyData: DashboardTableDataType = {
  [TimeFrame.AllTime]: NaN,
  [TimeFrame.Month]: NaN,
  [TimeFrame.Week]: NaN,
  [TimeFrame.Day]: NaN,
};

export const useDashboard = ({ wallet, selectedChain }: Props): HookReturnType => {
  const [searchParams] = useSearchParams();

  const queryWallet = searchParams.get('address');
  const queryVault = searchParams.get('vault');

  const [isAvailable, setIsAvailable] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [details, setDetails] = useState<DashboardDetailsType | null>(null);
  const [apr, setApr] = useState<DashboardTableDataType | null>(null);
  const [maxDrawdown, setMaxDrawdown] = useState<DashboardTableDataType | null>(null);
  const [sharpeRatio, setSharpeRatio] = useState<DashboardTableDataType | null>(null);
  const [sortinoRatio, setSortinoRatio] = useState<DashboardTableDataType | null>(null);
  const [calmarRatio, setCalmarRatio] = useState<DashboardTableDataType | null>(null);
  const [pnl, setPnl] = useState<DashboardDataType[]>([]);

  const updateGraphData = (data: DashboardDataType[]) => {
    if (!data?.length) {
      setPnl([]);
      return;
    }

    setPnl(data);
  };

  const getDashboardData = useCallback(
    async (loader?: boolean, graph?: boolean) => {
      try {
        if (loader) {
          setIsLoading(true);
        }

        const walletAddres = QUERY_ENABLED && queryWallet ? queryWallet : wallet;
        const vault = QUERY_ENABLED && queryVault ? queryVault : selectedChain;

        const detailsData = await getDetails(vault, walletAddres);
        const aprData = await getApr(vault);
        const maxDrawdownData = await getMaxDrawdown(vault);
        const sharpeRatioData = await getSharpeRatio(vault);
        const sortinoRatioData = await getSortinoRatio(vault);
        const calmarRatioData = await getCalmarRatio(vault);
        if (graph) {
          const pnlData = await getPnl(vault);
          setPnl(pnlData || []);
        }

        setDetails(detailsData || emptyDetails);
        setApr({ title: 'APR', ...(aprData || emptyData) });
        setMaxDrawdown({ title: 'Max Drawdown', ...(maxDrawdownData || emptyData) });
        setSharpeRatio({ title: 'Sharpe Ratio', ...(sharpeRatioData || emptyData) });
        setSortinoRatio({ title: 'Sortino Ratio', ...(sortinoRatioData || emptyData) });
        setCalmarRatio({ title: 'Calmar Ratio', ...(calmarRatioData || emptyData) });
      } catch (error) {
        setDetails(emptyDetails);
        setApr(emptyData);
        setMaxDrawdown(emptyData);
        setSharpeRatio(emptyData);
        setSortinoRatio(emptyData);
        setCalmarRatio(emptyData);
      } finally {
        setIsLoading(false);
      }
    },
    [selectedChain, wallet, queryVault, queryWallet]
  );

  useEffect(() => {
    const initDashboard = async () => {
      const walletAddres = QUERY_ENABLED && queryWallet ? queryWallet : wallet;
      const vault = QUERY_ENABLED && queryVault ? queryVault : selectedChain;
      try {
        setIsLoading(true);
        const { data } = await fetchDashboardData({
          vault_id: vault,
          account: walletAddres,
          metric: MetricType.Balance,
        });

        if (!data?.length || !Number(data?.[0].value)) {
          setIsAvailable(false);
        } else {
          setIsAvailable(true);
        }
      } catch (error) {
        setIsAvailable(false);
      } finally {
        setIsLoading(false);
      }
    };

    initDashboard();
  }, [selectedChain, wallet, queryVault, queryWallet]);

  useEffect(() => {
    let interval: any;
    if (isAvailable) {
      getDashboardData(true, true);
      interval = setInterval(() => getDashboardData(), Number(POLLING_INTERVAL) || 60000);
    }

    return () => clearInterval(interval);
  }, [getDashboardData, isAvailable]);

  return {
    details,
    apr,
    maxDrawdown,
    sharpeRatio,
    sortinoRatio,
    calmarRatio,
    pnl,
    isLoading,
    isAvailable,
    updateGraphData,
  };
};
