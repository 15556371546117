export enum GraphType {
  Line = 'line',
  Bar = 'bar',
}

export type GraphDataType = {
  date: number | string;
  value: number | string;
};

export type TooltipFormatterParams = {
  value: (number | string | Date)[];
  [key: string]: any;
};
