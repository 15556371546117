import cx from 'classnames';
import { ComponentProps } from 'react';

import css from './index.module.css';

type Props = ComponentProps<'button'> & {
  active?: boolean;
};

export const Chip = ({ active, children, className, type = 'button', ...props }: Props) => (
  <button
    className={cx(css.chip, className, 'headline', {
      [css.active]: active,
    })}
    type={type}
    {...props}
  >
    {children}
  </button>
);
