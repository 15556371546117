import { CurrencyIsoCode } from 'entities/types';
import { ComponentProps, createElement } from 'react';

import { AVAX } from './currencies/AVAX';
import { BNB } from './currencies/BNB';
import { ETH } from './currencies/ETH';
import { USD } from './currencies/USD';
import { USDC } from './currencies/USDC';

const icons: {
  [key in CurrencyIsoCode]: (props: ComponentProps<'svg'>) => JSX.Element;
} = {
  USD,
  USDC,
  ETH,
  AVAX,
  BNB,
};

type Props = {
  name: CurrencyIsoCode;
} & ComponentProps<'svg'>;

export const CurrencyIcon = ({ name, ...props }: Props) => {
  if (!name || !icons[name]) {
    return null;
  }

  return createElement(icons[name], { ...props });
};
