import { TimeFrame } from 'entities/types';

export type DashboardDetailsType = {
  balance: number | null;
  usdBalance: number | null;
  strategies: number | null;
};

export type DashboardTableDataType = {
  [key in TimeFrame]: number | null;
} & {
  title?: string;
};

export enum MetricType {
  USDBalance = 'vault.balance_usd',
  Balance = 'vault.balance_token',
  Strategies = 'vault.strategy_count',
  Apr = 'vault.apr',
  MaxDrawdown = 'vault.max_drawdown',
  SharpeRatio = 'vault.sharpe_ratio',
  SortinoRatio = 'vault.sortino_ratio',
  CalmarRatio = 'vault.calmar_ratio',
  PNL = 'vault.pnl',
}

export type DashboardDataType = {
  timestamp: number;
  value: number;
};
