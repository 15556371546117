/* eslint-disable consistent-return */
import cx from 'classnames';
import { useDashboardContext } from 'entities/dashboard/context';
import { getPnl } from 'entities/dashboard/helpers';
import { Graph } from 'entities/graph';
import { formatDate } from 'entities/graph/helpers';
import { GraphType, TooltipFormatterParams } from 'entities/graph/types';
import { useWalletContext } from 'entities/wallet';
import { ChainIds } from 'entities/wallet/chains';
import { ComponentProps, useState } from 'react';

import { Presets } from './components/Presets';
import { formatTooltipNum, getPresetStartDate } from './helper';

import css from './index.module.css';

type Props = ComponentProps<'section'>;

const PRESETS = ['24h', '7d', '30d', 'All Time'];

export const PnL = ({ className, ...props }: Props) => {
  const { pnl, updateGraphData } = useDashboardContext();
  const { selectedChain } = useWalletContext();
  const [selectedPreset, setSelectedPreset] = useState<string>('All Time');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const tooltipFormatter = (params: TooltipFormatterParams) => {
    const { value } = params[0];

    const [date, data] = value;

    const formattedDate = date ? formatDate(date, true) : '';
    const formattedData = data ? formatTooltipNum(data) : '';

    return `
            ${date ? `<p>${formattedDate}</p><br/>` : ''}
            ${formattedData ? `<p>${formattedData}%</p>` : null}
            `;
  };

  const fetchPnl = async (startTime?: string) => {
    try {
      setIsLoading(true);
      const data = await getPnl(selectedChain as ChainIds, startTime);
      updateGraphData(data || []);
    } catch (err) {
      updateGraphData([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePresetClick = async (preset: string) => {
    setSelectedPreset(preset);
    const start = getPresetStartDate(preset)?.getTime();

    if (!start) {
      fetchPnl();
      return;
    }

    const startTime = Math.floor(start / 1000);

    fetchPnl(startTime.toString());
  };

  return (
    <section className={cx(css.wrapper, className)} {...props}>
      <div className="content">
        <h2 className={css.title}>P&L Diagram, (%)</h2>
        <Presets
          activePreset={selectedPreset}
          handlePresetClick={handlePresetClick}
          presets={PRESETS}
          disabled={!pnl || isLoading}
        />
        <Graph
          data={pnl.map(({ timestamp, value }) => ({
            date: timestamp * 1000,
            value,
          }))}
          type={GraphType.Line}
          tooltip
          tooltipFormatter={tooltipFormatter}
          isLoading={isLoading}
        />
      </div>
    </section>
  );
};
