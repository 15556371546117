import { Web3Modal } from '@web3modal/react';
import { CurrencyIsoCode } from 'entities/types';
import { ReactNode, createContext, useContext, useMemo } from 'react';
import { WALLETCONNECT_PROJECT } from 'shared/lib/constants/global';

import { Chain, ChainIds } from '../chains';
import { ethereumClient } from '../config';
import { useWallet } from '../hooks/useWallet';
import { WalletType } from '../types';

type WalletContextType = {
  wallet: WalletType | null;
  selectedChain: ChainIds | null;
  onChainChange: (chain: ChainIds) => void;
  openWalletConnect: () => Promise<void>;
  connectMetaMask: (chain?: string) => Promise<void>;
  logout: () => void;
  isLoading: boolean;
  balanceToken: CurrencyIsoCode | null;
  isConnected: boolean;
};

type Props = {
  children: ReactNode;
};

const WalletContext = createContext<WalletContextType>({} as WalletContextType);

export const WalletContextProvider = ({ children }: Props) => {
  const {
    wallet,
    selectedChain,
    onChainChange,
    openWalletConnect,
    logout,
    connectMetaMask,
    isLoading,
    balanceToken,
    isConnected,
  } = useWallet();

  const values = useMemo(
    () => ({
      wallet,
      selectedChain,
      onChainChange,
      openWalletConnect,
      logout,
      connectMetaMask,
      isLoading,
      balanceToken,
      isConnected,
    }),
    [
      wallet,
      selectedChain,
      onChainChange,
      openWalletConnect,
      logout,
      connectMetaMask,
      isLoading,
      balanceToken,
      isConnected,
    ]
  );

  return (
    <>
      <WalletContext.Provider value={values}>{children}</WalletContext.Provider>
      {selectedChain ? (
        <Web3Modal
          projectId={WALLETCONNECT_PROJECT}
          defaultChain={Chain[selectedChain].walletConnect}
          ethereumClient={ethereumClient}
          themeVariables={{
            '--w3m-z-index': '100',
          }}
        />
      ) : null}
    </>
  );
};

export const useWalletContext = () => {
  const context = useContext(WalletContext);

  if (!context) {
    throw new Error('Wallet context is not available');
  }

  return context;
};
