import cx from 'classnames';
import { useDashboardContext } from 'entities/dashboard/context';
import { CurrencyIsoCode } from 'entities/types';
import { ComponentProps } from 'react';
import { formatNum } from 'shared/lib/utils/helpers';
import { CurrencyIcon } from 'shared/ui/atoms/icons/CurrencyIcon';
import { DotsLoader } from 'shared/ui/atoms/loaders/DotsLoader';

import { BalanceCard } from './components/BalanceCard';
import { StrategiesCard } from './components/StrategiesCard';

import css from './index.module.css';

type Props = ComponentProps<'section'>;

export const Details = ({ className, ...props }: Props) => {
  const { details } = useDashboardContext();

  return (
    <section className={cx(css.wrapper, className)} {...props}>
      <div className={cx('content')}>
        <h2 className={cx('title1', css.title)}>Vault Details</h2>
        {details ? (
          <ul className={css.cards}>
            <li>
              <BalanceCard
                title="Balance"
                icon={<CurrencyIcon name={CurrencyIsoCode.USDC} />}
                value={`${formatNum(details.balance)} ${CurrencyIsoCode.USDC}`}
              />
            </li>
            <li>
              <BalanceCard
                title="USD Equivalent"
                icon={<CurrencyIcon name={CurrencyIsoCode.USD} />}
                value={`$${formatNum(details.usdBalance, 2)}`}
              />
            </li>
            <li>
              <StrategiesCard title={`Active\nStrategies`} value={details.strategies} />
            </li>
          </ul>
        ) : (
          <div className={css.loader}>
            <DotsLoader />
          </div>
        )}
      </div>
    </section>
  );
};
