import axios, { AxiosError } from 'axios';
import { ApiErrorType } from 'entities/types';
import { API, DEFAULT_ERROR_MESSAGE } from 'shared/lib/constants/global';

const Axios = axios.create({
  baseURL: API,
});

Axios.interceptors.response.use(
  (response) => response,

  (error: AxiosError<any, any>) => {
    const response: ApiErrorType = {
      code: 500,
      message: DEFAULT_ERROR_MESSAGE,
    };

    if (error?.status) {
      response.code = Number(error.status);
    }

    if (error?.response) {
      const { response: res } = error;
      const message = res?.data || res?.statusText || DEFAULT_ERROR_MESSAGE;
      response.message = message;
    }

    if (!error?.response) {
      return Promise.reject(response);
    }

    return Promise.reject(response);
  }
);

export default Axios;
