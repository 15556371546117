import { ComponentProps } from 'react';
import cx from 'classnames';

import css from './index.module.css';

type Props = {
  title: string;
} & ComponentProps<'thead'>;

export const THead = ({ title, className, ...props }: Props) => (
  <thead className={cx(css.wrapper, className)} {...props}>
    <tr>
      <th className="body">{title}</th>
    </tr>
  </thead>
);
