import { DashboardTableDataType } from 'entities/dashboard/types';
import { TimeFrame } from 'entities/types';
import { formatNum, isNotEmpty } from 'shared/lib/utils/helpers';

import css from './index.module.css';

type Props = {
  data: DashboardTableDataType;
  valuePostfix?: string;
  valuePrefix?: string;
};

export const TRow = ({ data, valuePostfix, valuePrefix }: Props) => (
  <tr className={css.row}>
    <td className="title3">{data?.title}</td>
    <td className="title3">
      {isNotEmpty(data[TimeFrame.AllTime])
        ? `${(valuePrefix || '') + formatNum(data[TimeFrame.AllTime]) + (valuePostfix || '')}`
        : '-'}
    </td>
    <td className="title3">
      {isNotEmpty(data[TimeFrame.Month])
        ? `${(valuePrefix || '') + formatNum(data[TimeFrame.Month]) + (valuePostfix || '')}`
        : '-'}
    </td>
    <td className="title3">
      {isNotEmpty(data[TimeFrame.Week])
        ? `${(valuePrefix || '') + formatNum(data[TimeFrame.Week]) + (valuePostfix || '')}`
        : '-'}
    </td>
    <td className="title3">
      {isNotEmpty(data[TimeFrame.Day])
        ? `${(valuePrefix || '') + formatNum(data[TimeFrame.Day]) + (valuePostfix || '')}`
        : '-'}
    </td>
  </tr>
);
