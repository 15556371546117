import cx from 'classnames';

import css from './index.module.css';

export const Unauthorized = () => (
  <section className={css.wrapper}>
    <div className={cx('content', css.inner)}>
      <img
        src="/images/common/CoinchangeShield.png"
        alt="Coinchange Shield"
        width={202}
        height={157}
        loading="lazy"
        srcSet="/images/common/CoinchangeShield.png 1x, /images/common/CoinchangeShield@2x.png 2x"
      />
      <h1 className={css.title}>Coinchange DeFi Stablecoin Vaults</h1>
      <p className={cx(css.text, 'body')}>
        A Coinchange Vault represents a portfolio of DeFi strategies on a given blockchain.
        <br />
        Contact Coinchange to request a deposit or a withdrawal to be made for a selected vault.
      </p>
      <div className={css.info}>
        <p className="body">
          To view the balances and performance of your investment in one of the Coinchange
          stablecoin vaults:
        </p>
        <ol className={cx('body', css.list)}>
          <li>Select the blockchain where you have a Coinchange vault position</li>
          <li>Connect your wallet to view the financial performance dashboard</li>
        </ol>
      </div>
    </div>
  </section>
);
