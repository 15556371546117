export const formatWalletAddress = (address: string) => {
  if (!address) {
    return '';
  }

  if (address?.length <= 10) {
    return address;
  }

  const firstPart = address.slice(0, 6);
  const secondPart = address.slice(-4);

  return `${firstPart}...${secondPart}`;
};

export const getTimeStamp = (date: Date) => Math.floor(date.getTime() / 1000);

export const formatNum = (num: number | string | null, precision = 4) => {
  if (!num && num !== 0) {
    return '';
  }

  let fixedNumberString = typeof num !== 'string' ? num.toString() : num;

  const splittedString = fixedNumberString.split('.');

  if (splittedString?.length > 1) {
    const string = splittedString[1];
    let part;

    if (string[precision - 1] !== '0') {
      part = string.slice(0, precision);
    } else {
      const positiveNum = string.split('').findIndex((el) => +el > 0);
      part = string.slice(0, positiveNum + 2 || precision);
    }
    const res = [splittedString[0], part].join('.');

    fixedNumberString = Number(res).toString();
  } else {
    fixedNumberString = Number(fixedNumberString).toFixed(precision);
  }

  const parts = fixedNumberString.split('.');

  if (parts[1]?.length) {
    parts[1] = parts[1].padEnd(precision, '0');
  }

  let minusSign = '';
  parts[0] = parts[0]
    .replace(/^-/g, () => {
      minusSign = '-';
      return '';
    })
    .replace(/^0+\d/g, (match) => match.replace(/^0/, ''))
    .split('')
    .reverse()
    .join('')
    .replace(/\d{3}(?=.)/g, '$&,')
    .split('')
    .reverse()
    .join('');

  return `${minusSign}${parts.join('.')}`;
};

export const isNotEmpty = (num: number | null) => num || num === 0;
