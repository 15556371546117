import cx from 'classnames';
import { ComponentProps } from 'react';
import { Chip } from 'shared/ui/atoms/Chip';

import css from './index.module.css';

type Props = {
  presets: string[];
  handlePresetClick: (preset: string) => void;
  activePreset: string;
  disabled?: boolean;
} & ComponentProps<'ul'>;

export const Presets = ({
  presets,
  className,
  handlePresetClick,
  activePreset,
  disabled,
  ...props
}: Props) => {
  if (!presets?.length) {
    return null;
  }
  return (
    <ul className={cx(css.presets)} {...props}>
      {presets.map((el) => (
        <li key={el}>
          <Chip
            disabled={disabled}
            active={activePreset === el}
            type="button"
            onClick={() => handlePresetClick(el)}
          >
            {el}
          </Chip>
        </li>
      ))}
    </ul>
  );
};
