import { ComponentProps } from 'react';

export const AVAX = (props: ComponentProps<'svg'>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_6403_1098)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.9895 16.0002C31.9895 24.8251 24.8309 31.9789 16.0001 31.9789C7.16942 31.9789 0.0107422 24.8251 0.0107422 16.0002C0.0107422 7.1754 7.16942 0.0214844 16.0001 0.0214844C24.8309 0.0214844 31.9895 7.1754 31.9895 16.0002ZM11.4692 22.3589H8.36608C7.71403 22.3589 7.39195 22.3589 7.19556 22.2334C6.98344 22.0959 6.85382 21.8683 6.83811 21.617C6.82632 21.3855 6.98738 21.1028 7.30947 20.5375L14.9714 7.04138C15.2974 6.46827 15.4624 6.18172 15.6705 6.07574C15.8944 5.96191 16.1615 5.96191 16.3854 6.07574C16.5936 6.18172 16.7586 6.46827 17.0846 7.04138L18.6597 9.78912L18.6678 9.80314C19.0199 10.418 19.1985 10.7298 19.2764 11.057C19.3628 11.4142 19.3628 11.791 19.2764 12.1482C19.1979 12.478 19.0211 12.792 18.6637 13.4161L14.639 20.5258L14.6286 20.544C14.2742 21.1639 14.0946 21.4781 13.8456 21.7151C13.5746 21.9742 13.2485 22.1625 12.8911 22.2687C12.5651 22.3589 12.1998 22.3589 11.4692 22.3589ZM19.3055 22.3589H23.7518C24.4078 22.3589 24.7378 22.3589 24.9343 22.2296C25.1464 22.0921 25.2799 21.8604 25.2918 21.6094C25.3031 21.3853 25.1455 21.1136 24.8368 20.5812C24.8262 20.5631 24.8155 20.5446 24.8047 20.5259L22.5774 16.7183L22.5521 16.6754C22.2391 16.1465 22.0812 15.8794 21.8783 15.7762C21.6545 15.6624 21.3911 15.6624 21.1673 15.7762C20.9631 15.8822 20.7981 16.1609 20.4721 16.7222L18.2528 20.5298L18.2452 20.5429C17.9203 21.1033 17.7579 21.3834 17.7697 21.6132C17.7854 21.8645 17.915 22.0959 18.1271 22.2334C18.3196 22.3589 18.6495 22.3589 19.3055 22.3589Z"
        fill="#E84142"
      />
    </g>
    <defs>
      <clipPath id="clip0_6403_1098">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
