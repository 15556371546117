import { DashboardTableDataType } from 'entities/dashboard/types';
import { ComponentProps } from 'react';

import { TRow } from './components/TRow';

type Props = {
  data: DashboardTableDataType[];
  valuePostfix?: string;
  valuePrefix?: string;
} & ComponentProps<'tbody'>;

export const TBody = ({ data, title, valuePostfix, valuePrefix, ...props }: Props) => (
  <tbody {...props}>
    {data.map((el) => (
      <TRow
        valuePostfix={valuePostfix}
        valuePrefix={valuePrefix}
        data={el}
        key={`${el?.title}${el.allTime}`}
      />
    ))}
  </tbody>
);
