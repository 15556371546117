import { ComponentProps } from 'react';

export const ETH = (props: ComponentProps<'svg'>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_166_125)">
      <ellipse cx="16.0037" cy="16" rx="16" ry="16" fill="#6580E3" />
      <path
        d="M16.0017 5.3335L15.8618 5.79535V19.1961L16.0017 19.3317L22.3995 15.6548L16.0017 5.3335Z"
        fill="#C2CCF3"
      />
      <path d="M16.0015 5.3335L9.60352 15.6548L16.0015 19.3317V12.8274V5.3335Z" fill="white" />
      <path
        d="M16.0017 20.5095L15.9229 20.6029V25.3765L16.0017 25.6002L22.4034 16.8345L16.0017 20.5095Z"
        fill="#C2CCF3"
      />
      <path d="M16.0015 25.6002V20.5095L9.60352 16.8345L16.0015 25.6002Z" fill="white" />
      <path d="M16.0017 19.332L22.3995 15.6551L16.0017 12.8276V19.332Z" fill="#8499E8" />
      <path d="M9.60352 15.6551L16.0015 19.332V12.8276L9.60352 15.6551Z" fill="#C2CCF3" />
    </g>
    <defs>
      <clipPath id="clip0_166_125">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
