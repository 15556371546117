import ReactEcharts from 'echarts-for-react';
import { formatDataArray, formatDate } from 'entities/graph/helpers';
import { GraphDataType, GraphType, TooltipFormatterParams } from 'entities/graph/types';
import { CurrencyIsoCode } from 'entities/types';
import { formatNum } from 'shared/lib/utils/helpers';

import css from './index.module.css';

type Props = {
  data: GraphDataType[];
  minDaysToShow?: number;
  type: GraphType;
  currency?: CurrencyIsoCode;
  tooltip?: boolean;
  tooltipFormatter?: (params: TooltipFormatterParams) => string;
  isLoading?: boolean;
};

export const Graph = ({
  data,
  minDaysToShow = 1,
  type,
  currency,
  tooltip = false,
  tooltipFormatter,
  isLoading,
}: Props) => {
  const renderTooltip = (params: TooltipFormatterParams) => {
    if (params?.length && tooltipFormatter) {
      return tooltipFormatter(params);
    }

    return '';
  };

  const tooltipConfig = {
    show: tooltip,
    trigger: 'axis',
    formatter: (params: any) => renderTooltip(params),
    backgroundColor: '#000000',
    borderWidth: 0,
    padding: 16,
    extraCssText: 'z-index: 100;',
    textStyle: {
      color: '#ffffff',
      fontFamily: 'Roboto',
      fontSize: 12,
      fontWeight: 400,
    },
    transitionDuration: 0,
    position: (point: number[], p: any, d: any, r: any, size: { contentSize: number[] }) => {
      if (point[1] < size.contentSize[1] + 55) {
        return {
          left: point[0] - size.contentSize[0] / 2,
          top: point[1] + 24,
        };
      }
      return {
        left: point[0] - size.contentSize[0] / 2,
        top: point[1] - size.contentSize[1] - 8,
      };
    },
  };

  const dataZoomConfig = [
    {
      type: 'inside',
      minValueSpan: 3600 * 24 * 1000 * minDaysToShow,
      zoomLock: false,
      moveOnMouseMove: true,
      moveOnMouseWheel: false,
      zoomOnMouseWheel: false,
      rangeMode: ['value', 'value'],
    },
    {
      type: 'slider',
      minValueSpan: 3600 * 24 * 1000 * minDaysToShow,
      rangeMode: ['value', 'value'],
      labelFormatter: (value: number) => formatDate(value, true, true),
      width: '80%',
      left: '8%',
      textstyle: {
        overflow: 'break',
      },
    },
  ];

  const seriesConfig = {
    type,
    showSymbol: false,
    symbol: 'circle',
    symbolSize: 5,
    lineStyle: {
      color: '#0066BE',
    },
    itemStyle: {
      color: '#000000',
    },
    data: formatDataArray(data),
    cursor: 'default',
  };

  const axisConfig = {
    x: {
      type: 'time',
      boundaryGap: false,
      axisPointer: {
        type: 'line',
        lineStyle: {
          color: '#000000',
          type: 'dashed',
        },
      },
      minInterval: 3600 * 1000 * 24,
      axisLabel: {
        color: '#000000',
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: 12,
        align: 'center',
      },
      axisLine: {
        color: 'rgba(0, 0, 0, 0.2)',
      },
    },
    y: {
      type: 'value',
      position: 'right',
      axisLabel: {
        color: '#000000',
        width: 90,
        formatter: (value: number) => {
          if (!currency) {
            return formatNum(value, 6);
          }

          if (currency === CurrencyIsoCode.USD) {
            return `$${formatNum(value, 6)}`;
          }

          return `${formatNum(value, 6)} ${currency}`;
        },
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: 12,
        overflow: 'break',
      },
      axisLine: {
        color: 'rgba(0, 0, 0, 0.2)',
      },
    },
  };

  const option = {
    tooltip: tooltipConfig,
    xAxis: axisConfig.x,
    yAxis: axisConfig.y,
    grid: {
      left: 5,
      right: 100,
    },
    series: seriesConfig,
    dataZoom: dataZoomConfig,
  };
  return (
    <ReactEcharts
      className={css.graph}
      option={option}
      showLoading={!data || !data?.length || isLoading}
      loadingOption={{
        text: 'Loading...',
      }}
    />
  );
};
