import detectEthereumProvider from '@metamask/detect-provider';
import { useWeb3Modal } from '@web3modal/react';
import { CurrencyIsoCode } from 'entities/types';
import storage from 'processes/storage';
import { useCallback, useEffect, useState } from 'react';
import sendMessage from 'shared/ui/molecules/Snackbar/sendMessage';
import {
  useAccount,
  useBalance,
  useChainId,
  useConnect,
  useDisconnect,
  useSwitchNetwork,
} from 'wagmi';

import { Chain, ChainIds } from '../chains';
import { getSavedChain } from '../helpers';
import { Connection, WalletType } from '../types';

type HookReturnType = {
  wallet: WalletType | null;
  selectedChain: ChainIds | null;
  onChainChange: (chain: ChainIds) => void;
  openWalletConnect: () => Promise<void>;
  connectMetaMask: (chain?: string) => Promise<void>;
  logout: () => void;
  isLoading: boolean;
  balanceToken: CurrencyIsoCode | null;
  isConnected: boolean;
};

export const useWallet = (): HookReturnType => {
  const savedChain = getSavedChain();

  const [wallet, setWallet] = useState<WalletType | null>(null);
  const [balanceToken, setBalanceToken] = useState<CurrencyIsoCode | null>(null);
  const [selectedChain, setSelectedChain] = useState<ChainIds>(savedChain);
  const [hasProvider, setHasProvider] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { open } = useWeb3Modal();
  const { disconnect } = useDisconnect();
  const walletChain = useChainId();

  const { switchNetworkAsync } = useSwitchNetwork({
    chainId: Chain[selectedChain].id,
    onError(error) {
      console.error(error);
    },
  });

  const handleChainChanged = useCallback(() => window.location.reload(), []);

  const logout = useCallback(() => {
    disconnect();
    setWallet(null);
  }, [disconnect]);

  const { connector, isReconnecting, address, isConnected } = useAccount({
    onDisconnect: logout,
  });

  const { connectors, connectAsync } = useConnect({
    onError: (err) => console.error(err),
  });

  const { data: balanceData } = useBalance({
    address: (wallet?.address as `0x${string}`) || undefined,
    chainId: Chain[selectedChain].id,
  });

  useEffect(() => {
    if (balanceData) {
      setBalanceToken(balanceData.symbol as CurrencyIsoCode);
    }
  }, [balanceData]);

  const initWallet = useCallback(async () => {
    if (address && connector) {
      const connection = connector?.name.toLowerCase();
      setWallet({
        address,
        connection: connection as Connection,
      });
    }
  }, [address, connector]);

  const switchChain = useCallback(
    async (chain: number) => {
      try {
        setIsLoading(true);
        switchNetworkAsync?.(chain);
      } catch (error) {
        sendMessage({
          error: "Can't switch chain",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [switchNetworkAsync]
  );

  useEffect(() => {
    const handleChain = async () => {
      const selectedChainId = Chain[selectedChain].id;
      const connectorChain = await connector?.getChainId();
      if (walletChain !== selectedChainId || connectorChain !== selectedChainId) {
        switchChain(selectedChainId);
      }
    };

    if (!isLoading) {
      handleChain();
    }
  }, [selectedChain, walletChain, switchChain, isLoading, connector]);

  const openWalletConnect = async () => {
    try {
      await open();
    } catch (error) {
      console.error(error);
    }
  };

  const connectMetaMask = async () => {
    const metaMaskConnector = connectors.find((el) => el.name.toLowerCase() === 'metamask');
    if (hasProvider && metaMaskConnector && metaMaskConnector?.ready) {
      try {
        setIsLoading(true);
        connectAsync?.({
          connector: metaMaskConnector,
        });
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      sendMessage({
        error: 'Install MetaMask extension',
      });
    }
  };

  const onChainChange = (chain: ChainIds) => {
    setSelectedChain(chain);
    storage.set('chain', chain);
  };

  const getMetaMaskProvider = useCallback(async () => {
    try {
      setIsLoading(true);
      const provider = await detectEthereumProvider({ silent: true });
      setHasProvider(Boolean(provider));

      if (provider) {
        window.ethereum.on('accountsChanged', () => window.location.reload());
        window.ethereum.on('chainChanged', handleChainChanged);
        window.ethereum.on('disconnect', logout);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [handleChainChanged, logout]);

  useEffect(() => {
    getMetaMaskProvider();

    return () => {
      window.ethereum?.removeListener('accountsChanged', () => window.location.reload());
      window.ethereum?.removeListener('chainChanged', handleChainChanged);
      window.ethereum?.removeListener('disconnect', logout);
    };
  }, [getMetaMaskProvider, handleChainChanged, logout]);

  useEffect(() => {
    if (address && isConnected) {
      initWallet();
    }
  }, [address, initWallet, isConnected]);

  return {
    wallet,
    selectedChain,
    onChainChange,
    openWalletConnect,
    logout,
    connectMetaMask,
    isLoading: isReconnecting || isLoading,
    balanceToken,
    isConnected,
  };
};
