export const RANGES = {
  oneDay: (d: Date) => new Date(d.setHours(d.getHours() - 24)),
  oneWeek: (d: Date) => new Date(d.setDate(d.getDate() - 7)),
  thirtyDays: (d: Date) => new Date(d.setDate(d.getDate() - 30)),
};

export const getPresetStartDate = (preset: string) => {
  const d = new Date();
  const difference = d.getTimezoneOffset() * 60000;
  const date = new Date(d.valueOf() + difference);

  switch (preset) {
    case '24h':
      return RANGES.oneDay(date);
    case '7d':
      return RANGES.oneWeek(date);
    case '30d':
      return RANGES.thirtyDays(date);
    default:
      return undefined;
  }
};

export const formatTooltipNum = (num: number | string | null, precision = 4) => {
  if (!num && num !== 0) {
    return '';
  }

  let fixedNumberString = typeof num !== 'string' ? num.toString() : num;

  const splittedString = fixedNumberString.split('.');

  if (splittedString?.length > 1) {
    const string = splittedString[1];
    let part;

    if (string[precision - 1] !== '0' && string[precision] !== '0') {
      part = string.slice(0, precision + 1);
    } else if (string[precision - 1] !== '0' && string[precision] === '0') {
      part = string.slice(0, precision + 2);
    } else {
      const positiveNum = string.split('').findIndex((el) => +el > 0);
      part = string.slice(0, positiveNum + 2 || precision);
    }
    const res = [splittedString[0], part].join('.');

    fixedNumberString = Number(res).toString();
  } else {
    fixedNumberString = Number(fixedNumberString).toFixed(precision);
  }

  const parts = fixedNumberString.split('.');

  if (parts[1]?.length) {
    parts[1] = parts[1].padEnd(precision, '0');
  }

  let minusSign = '';
  parts[0] = parts[0]
    .replace(/^-/g, () => {
      minusSign = '-';
      return '';
    })
    .replace(/^0+\d/g, (match) => match.replace(/^0/, ''))
    .split('')
    .reverse()
    .join('')
    .replace(/\d{3}(?=.)/g, '$&,')
    .split('')
    .reverse()
    .join('');

  return `${minusSign}${parts.join('.')}`;
};
