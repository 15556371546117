import { DashboardContextProvider } from 'entities/dashboard/context';
import { useWalletContext } from 'entities/wallet';
import { Dashboard } from 'pages/Dashboard';
import { Navigate, Route, Routes } from 'react-router-dom';
import { DotsLoader } from 'shared/ui/atoms/loaders/DotsLoader';
import { PageTemplate } from 'shared/ui/templates/PageTemplate';

export const ProtectedRoutes = () => {
  const { wallet, selectedChain } = useWalletContext();

  if (!wallet || !selectedChain) {
    return (
      <PageTemplate>
        <section>
          <DotsLoader />
        </section>
      </PageTemplate>
    );
  }
  return (
    <PageTemplate>
      <Routes>
        <Route
          path="/dashboard"
          element={
            <DashboardContextProvider wallet={wallet.address} selectedChain={selectedChain}>
              <Dashboard />
            </DashboardContextProvider>
          }
        />
        <Route path="*" element={<Navigate to="/dashboard" replace />} />
      </Routes>
    </PageTemplate>
  );
};
