import { useDashboardContext } from 'entities/dashboard/context';
import { DotsLoader } from 'shared/ui/atoms/loaders/DotsLoader';

import { Details } from './components/Details';
import { EmptyBalance } from './components/EmptyBalance';
import { Metrics } from './components/Metrics';
import { PnL } from './components/PnL';

import css from './index.module.css';

export const Dashboard = () => {
  const { isLoading, isAvailable } = useDashboardContext();

  if (isLoading) {
    return (
      <section className={css.loader}>
        <DotsLoader />
      </section>
    );
  }

  if (!isAvailable) {
    return <EmptyBalance />;
  }

  return (
    <>
      <Details />
      <Metrics />
      <PnL />
    </>
  );
};
