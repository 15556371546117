import cx from 'classnames';
import { useDashboardContext } from 'entities/dashboard/context';
import { DotsLoader } from 'shared/ui/atoms/loaders/DotsLoader';

import { Table } from '../Table';

import css from './index.module.css';

export const Metrics = () => {
  const { apr, maxDrawdown, sharpeRatio, sortinoRatio, calmarRatio } = useDashboardContext();

  return (
    <section className={css.wrapper}>
      <div className={cx('content')}>
        <div className={css.head}>
          <h2 className="title1">Metrics</h2>
          <span className="body">All-Time</span>
          <span className="body">30-Day</span>
          <span className="body">7-Day</span>
          <span className="body">24-Hour</span>
        </div>
        <div className={css.tables}>
          {apr ? (
            <Table data={[apr]} title="Performance" valuePostfix="%" />
          ) : (
            <div className={css.loader}>
              <DotsLoader />
            </div>
          )}
          {maxDrawdown ? (
            <Table data={[maxDrawdown]} title="Risk" valuePostfix="%" />
          ) : (
            <div className={css.loader}>
              <DotsLoader />
            </div>
          )}
          {sharpeRatio && sortinoRatio && calmarRatio ? (
            <Table data={[sharpeRatio, sortinoRatio, calmarRatio]} title="Ratios" />
          ) : (
            <div className={css.loader}>
              <DotsLoader />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
