import cx from 'classnames';
import { useWalletContext } from 'entities/wallet';
import { Connection } from 'entities/wallet/types';
import { useState } from 'react';
import { Button } from 'shared/ui/atoms/Button';
import { MetaMaskIcon } from 'shared/ui/atoms/icons/MetaMaskIcon';
import { WalletConnectIcon } from 'shared/ui/atoms/icons/WalletConnectIcon';
import { LogoutModal } from 'shared/ui/molecules/LogoutModal';

import css from './index.module.css';

export const EmptyBalance = () => {
  const { wallet, logout } = useWalletContext();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onCloseLogoutModal = () => {
    setIsOpen(false);
  };

  const disconnectHandler = () => {
    logout();
    onCloseLogoutModal();
  };

  if (!wallet) {
    return null;
  }

  const renderWalletIcon = () => {
    if (wallet.connection === Connection.MetaMask) {
      return <MetaMaskIcon large />;
    }

    if (wallet.connection === Connection.WalletConnect) {
      return <WalletConnectIcon large />;
    }

    return null;
  };

  return (
    <section className={css.wrapper}>
      <div className={cx('content')}>
        <div className={css.card}>
          <div className={css.wallet}>{renderWalletIcon()}</div>
          <h1 className={css.title}>Connected Wallet Has No Position in the Selected Vault</h1>
          <Button onClick={() => setIsOpen(true)} className={css.disconnect} variant="primary">
            Disconnect Wallet
          </Button>
        </div>
      </div>
      <LogoutModal
        wallet={wallet.address}
        isOpen={isOpen}
        onClose={onCloseLogoutModal}
        onDisconnect={disconnectHandler}
      />
    </section>
  );
};
