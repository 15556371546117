import { AxiosResponse } from 'axios';
import { DashboardDataType, MetricType } from 'entities/dashboard/types';
import { TimeFrame } from 'entities/types';

import Axios from '../axios';

export type DashboardParams = {
  vault_id: string;
  account?: string;
  metric: MetricType;
  time_frame?: TimeFrame;
  start_ts?: string;
  end_ts?: string;
};

const fetchDashboardData = (data: DashboardParams): Promise<AxiosResponse<DashboardDataType[]>> => {
  const query = new URLSearchParams(data);

  return Axios.get(`/dashboard/query?${query.toString()}`);
};

export { fetchDashboardData };
