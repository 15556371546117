import { GraphDataType } from '../types';

export const formatDate = (date: string | number, time?: boolean, breakWords?: boolean) => {
  const d = new Date(date);

  const day = d.getDate().toString().padStart(2, '0');
  const month = (d.getMonth() + 1).toString().padStart(2, '0');
  const year = d.getFullYear().toString();
  const hours = d.getHours().toString().padStart(2, '0');
  const minutes = d.getMinutes().toString().padStart(2, '0');

  if (time) {
    return `${year}-${month}-${day}${breakWords ? `\n` : ' '}${hours}:${minutes}`;
  }

  return `${year}-${month}-${day}`;
};

export const formatDataArray = (arr: GraphDataType[]) =>
  arr.map(({ date, value }) => {
    const d = new Date(date);
    const difference = d.getTimezoneOffset() * 60_000;
    const formattedDate = new Date(d.valueOf() + difference);

    return [formattedDate, value];
  });
