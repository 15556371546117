import { SUPPORTED_NETWORKS } from 'shared/lib/constants/global';
import { arbitrum, avalanche, bsc } from 'wagmi/chains';

import { ChainType, ChainsItemType } from './types';

export enum ChainIds {
  Avalanche = '1',
  Arbitrum = '0',
  BSC = '3',
}

export enum Blockchain {
  Arbitrum = 'Arbitrum',
  Avalanche = 'Avalanche',
  BSC = 'BSC',
}

export const chainsOptions: ChainsItemType[] = [
  {
    value: ChainIds.Avalanche,
    label: 'Avalanche',
    icon: Blockchain.Avalanche,
    shortName: 'avalanche',
  },
  {
    value: ChainIds.Arbitrum,
    label: 'Arbitrum One',
    icon: Blockchain.Arbitrum,
    shortName: 'arbitrum',
  },
  {
    value: ChainIds.BSC,
    label: 'Binance Smart Chain',
    icon: Blockchain.BSC,
    shortName: 'bsc',
  },
].filter(({ shortName }) => {
  if (!SUPPORTED_NETWORKS?.length) {
    return true;
  }

  if (shortName) {
    return SUPPORTED_NETWORKS.includes(shortName);
  }
  return false;
});

export const Chain: ChainType = {
  [ChainIds.Arbitrum]: arbitrum,
  [ChainIds.Avalanche]: avalanche,
  [ChainIds.BSC]: bsc,
};

export const CHAINS = [avalanche, arbitrum, bsc].filter((el) => {
  if (SUPPORTED_NETWORKS?.length) {
    return SUPPORTED_NETWORKS.includes(el.network);
  }

  return true;
});
