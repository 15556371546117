export enum TimeFrame {
  AllTime = 'allTime',
  Month = '30d',
  Week = '7d',
  Day = '24h',
}

export enum MessageType {
  WARNING = 'warning',
  INFO = 'info',
  SUCCESS = 'success',
  DANGER = 'danger',
}

export type ApiErrorType = {
  code: number;
  message: string;
};

export enum CurrencyIsoCode {
  USD = 'USD',
  USDC = 'USDC',
  ETH = 'ETH',
  AVAX = 'AVAX',
  BNB = 'BNB',
}
