import storage from 'processes/storage';
import { SUPPORTED_NETWORKS } from 'shared/lib/constants/global';

import { Chain, ChainIds, chainsOptions } from '../chains';

const getSavedChain = (): ChainIds => {
  const savedChain = storage.get('chain');

  if (savedChain) {
    if (!SUPPORTED_NETWORKS?.length) {
      return savedChain as ChainIds;
    }

    return SUPPORTED_NETWORKS.includes(Chain[savedChain]?.network?.toLowerCase())
      ? (savedChain as ChainIds)
      : chainsOptions[0].value;
  }

  return chainsOptions[0].value;
};

export { getSavedChain };
