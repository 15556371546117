import { TimeFrame } from 'entities/types';
import { fetchDashboardData } from 'shared/api/dashboard';

import { DashboardDetailsType, DashboardTableDataType, MetricType } from '../types';

const getApr = async (vault: string): Promise<DashboardTableDataType | null> => {
  const params = {
    vault_id: vault,
    metric: MetricType.Apr,
  };
  const promises = Promise.all([
    fetchDashboardData(params),
    fetchDashboardData({
      ...params,
      time_frame: TimeFrame.Month,
    }),
    fetchDashboardData({
      ...params,
      time_frame: TimeFrame.Week,
    }),
    fetchDashboardData({
      ...params,
      time_frame: TimeFrame.Day,
    }),
  ]);
  try {
    const [{ data: allTime }, { data: month }, { data: week }, { data: day }] = await promises;
    return {
      [TimeFrame.AllTime]: allTime?.length ? Number(allTime[0]?.value) : NaN,
      [TimeFrame.Month]: month?.length ? Number(month[0]?.value) : NaN,
      [TimeFrame.Week]: week?.length ? Number(week[0]?.value) : NaN,
      [TimeFrame.Day]: day?.length ? Number(day[0]?.value) : NaN,
    };
  } catch (error) {
    return null;
  }
};

const getDetails = async (vault: string, wallet: string): Promise<DashboardDetailsType | null> => {
  const privateParams = {
    vault_id: vault,
    account: wallet,
  };
  const promises = Promise.all([
    fetchDashboardData({
      vault_id: vault,
      metric: MetricType.Strategies,
    }),
    fetchDashboardData({
      ...privateParams,
      metric: MetricType.USDBalance,
    }),
    fetchDashboardData({
      ...privateParams,
      metric: MetricType.Balance,
    }),
  ]);
  try {
    const [{ data: strategies }, { data: usdBalance }, { data: balance }] = await promises;
    return {
      usdBalance: usdBalance?.length ? Number(usdBalance[0]?.value) : NaN,
      strategies: strategies.length ? Number(strategies[0]?.value) : NaN,
      balance: balance?.length ? Number(balance[0]?.value) : NaN,
    };
  } catch (err) {
    return null;
  }
};

const getMaxDrawdown = async (vault: string): Promise<DashboardTableDataType | null> => {
  const params = {
    vault_id: vault,
    metric: MetricType.MaxDrawdown,
  };
  const promises = Promise.all([
    fetchDashboardData(params),
    fetchDashboardData({
      ...params,
      time_frame: TimeFrame.Month,
    }),
    fetchDashboardData({
      ...params,
      time_frame: TimeFrame.Week,
    }),
    fetchDashboardData({
      ...params,
      time_frame: TimeFrame.Day,
    }),
  ]);
  try {
    const [{ data: allTime }, { data: month }, { data: week }, { data: day }] = await promises;
    return {
      [TimeFrame.AllTime]: allTime?.length ? Number(allTime[0]?.value) : NaN,
      [TimeFrame.Month]: month?.length ? Number(month[0]?.value) : NaN,
      [TimeFrame.Week]: week?.length ? Number(week[0]?.value) : NaN,
      [TimeFrame.Day]: day?.length ? Number(day[0]?.value) : NaN,
    };
  } catch (error) {
    return null;
  }
};

const getSharpeRatio = async (vault: string): Promise<DashboardTableDataType | null> => {
  const params = {
    vault_id: vault,
    metric: MetricType.SharpeRatio,
  };
  const promises = Promise.all([
    fetchDashboardData(params),
    fetchDashboardData({
      ...params,
      time_frame: TimeFrame.Month,
    }),
    fetchDashboardData({
      ...params,
      time_frame: TimeFrame.Week,
    }),
    fetchDashboardData({
      ...params,
      time_frame: TimeFrame.Day,
    }),
  ]);
  try {
    const [{ data: allTime }, { data: month }, { data: week }, { data: day }] = await promises;
    return {
      [TimeFrame.AllTime]: allTime?.length ? Number(allTime[0]?.value) : NaN,
      [TimeFrame.Month]: month?.length ? Number(month[0]?.value) : NaN,
      [TimeFrame.Week]: week?.length ? Number(week[0]?.value) : NaN,
      [TimeFrame.Day]: day?.length ? Number(day[0]?.value) : NaN,
    };
  } catch (error) {
    return null;
  }
};

const getSortinoRatio = async (vault: string): Promise<DashboardTableDataType | null> => {
  const params = {
    vault_id: vault,
    metric: MetricType.SortinoRatio,
  };
  const promises = Promise.all([
    fetchDashboardData(params),
    fetchDashboardData({
      ...params,
      time_frame: TimeFrame.Month,
    }),
    fetchDashboardData({
      ...params,
      time_frame: TimeFrame.Week,
    }),
    fetchDashboardData({
      ...params,
      time_frame: TimeFrame.Day,
    }),
  ]);
  try {
    const [{ data: allTime }, { data: month }, { data: week }, { data: day }] = await promises;
    return {
      [TimeFrame.AllTime]: allTime?.length ? Number(allTime[0]?.value) : NaN,
      [TimeFrame.Month]: month?.length ? Number(month[0]?.value) : NaN,
      [TimeFrame.Week]: week?.length ? Number(week[0]?.value) : NaN,
      [TimeFrame.Day]: day?.length ? Number(day[0]?.value) : NaN,
    };
  } catch (error) {
    return null;
  }
};

const getCalmarRatio = async (vault: string): Promise<DashboardTableDataType | null> => {
  const params = {
    vault_id: vault,
    metric: MetricType.CalmarRatio,
  };
  const promises = Promise.all([
    fetchDashboardData(params),
    fetchDashboardData({
      ...params,
      time_frame: TimeFrame.Month,
    }),
    fetchDashboardData({
      ...params,
      time_frame: TimeFrame.Week,
    }),
    fetchDashboardData({
      ...params,
      time_frame: TimeFrame.Day,
    }),
  ]);
  try {
    const [{ data: allTime }, { data: month }, { data: week }, { data: day }] = await promises;
    return {
      [TimeFrame.AllTime]: allTime?.length ? Number(allTime[0]?.value) : NaN,
      [TimeFrame.Month]: month?.length ? Number(month[0]?.value) : NaN,
      [TimeFrame.Week]: week?.length ? Number(week[0]?.value) : NaN,
      [TimeFrame.Day]: day?.length ? Number(day[0]?.value) : NaN,
    };
  } catch (error) {
    return null;
  }
};

const getPnl = async (vault: string, startTime = '0') => {
  const params = {
    vault_id: vault,
    start_ts: startTime,
    metric: MetricType.PNL,
  };
  try {
    const { data } = await fetchDashboardData(params);
    return data;
  } catch (error) {
    return null;
  }
};

export {
  getApr,
  getCalmarRatio,
  getDetails,
  getMaxDrawdown,
  getPnl,
  getSharpeRatio,
  getSortinoRatio,
};
