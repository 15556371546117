import { ComponentProps, ReactNode } from 'react';
import cx from 'classnames';

import css from './index.module.css';

type Props = {
  title: string;
  value?: string;
  icon?: ReactNode;
} & ComponentProps<'div'>;

export const BalanceCard = ({ title, value, icon, className, ...props }: Props) => (
  <div className={cx(css.card, className)}>
    {icon || null}
    <div className={css.info}>
      <p className="body">{title}</p>
      {value ? <p className="title3">{value}</p> : null}
    </div>
  </div>
);
