import { ComponentProps } from 'react';

export const USD = (props: ComponentProps<'svg'>) => (
  <svg
    width="32"
    height="34"
    viewBox="0 0 32 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_15722_143179)">
      <path
        d="M16 33.0017C24.8366 33.0017 32 25.8374 32 16.9999C32 8.1623 24.8366 0.998047 16 0.998047C7.16344 0.998047 0 8.1623 0 16.9999C0 25.8374 7.16344 33.0017 16 33.0017Z"
        fill="#F0F0F0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.6517 8.65097H15.3044V4.47656H25.9598C27.4199 5.63969 28.6723 7.05254 29.6517 8.65097ZM32.0001 16.9997H15.3044V12.8253H31.4489C31.8076 14.1563 32.0001 15.5554 32.0001 16.9997ZM16.0001 33.0015C19.7657 33.0015 23.2267 31.6999 25.9598 29.5229H6.04035C8.77347 31.6999 12.2345 33.0015 16.0001 33.0015ZM29.6517 25.3485H2.34846C1.56214 24.0653 0.952332 22.6626 0.55127 21.1741H31.4489C31.0478 22.6626 30.438 24.0653 29.6517 25.3485Z"
        fill="#D80027"
      />
      <path
        d="M7.4115 3.49695H8.86956L7.51331 4.48238L8.03137 6.07687L6.67519 5.09145L5.319 6.07687L5.7665 4.6994C4.57237 5.6942 3.52575 6.85971 2.66325 8.15835H3.13044L2.26712 8.78561C2.13262 9.01001 2.00362 9.23798 1.88 9.46931L2.29225 10.7383L1.52313 10.1794C1.33194 10.5845 1.15706 10.9987 0.999875 11.4216L1.45406 12.8198H3.13044L1.77419 13.8052L2.29225 15.3997L0.936063 14.4142L0.123687 15.0046C0.042375 15.6583 0 16.3241 0 16.9999H16C16 8.16235 16 7.12049 16 0.998047C12.8393 0.998047 9.89281 1.91503 7.4115 3.49695ZM8.03137 15.3997L6.67519 14.4142L5.319 15.3997L5.83706 13.8052L4.48081 12.8198H6.15719L6.67519 11.2253L7.19319 12.8198H8.86956L7.51331 13.8052L8.03137 15.3997ZM7.51331 9.14378L8.03137 10.7383L6.67519 9.75285L5.319 10.7383L5.83706 9.14378L4.48081 8.15835H6.15719L6.67519 6.56386L7.19319 8.15835H8.86956L7.51331 9.14378ZM13.7705 15.3997L12.4143 14.4142L11.0581 15.3997L11.5762 13.8052L10.2199 12.8198H11.8963L12.4143 11.2253L12.9323 12.8198H14.6087L13.2524 13.8052L13.7705 15.3997ZM13.2524 9.14378L13.7705 10.7383L12.4143 9.75285L11.0581 10.7383L11.5762 9.14378L10.2199 8.15835H11.8963L12.4143 6.56386L12.9323 8.15835H14.6087L13.2524 9.14378ZM13.2524 4.48238L13.7705 6.07687L12.4143 5.09145L11.0581 6.07687L11.5762 4.48238L10.2199 3.49695H11.8963L12.4143 1.90246L12.9323 3.49695H14.6087L13.2524 4.48238Z"
        fill="#0052B4"
      />
    </g>
    <defs>
      <clipPath id="clip0_15722_143179">
        <rect width="32" height="32.0036" fill="white" transform="translate(0 0.998047)" />
      </clipPath>
    </defs>
  </svg>
);
